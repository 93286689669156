import {
  Avatar,
  AvatarGroup,
  Button,
  Flex,
  HStack,
  Spacer,
  Spinner,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Table,
  TableContainer,
  Tabs,
  Tag,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Wrap,
  WrapItem,
} from "@chakra-ui/react";
import { number } from "@recoiljs/refine";
import { MainFooter, MainHeader, Pagination, PhotoView, StoryView } from "components";
import { ProfileBasicInfo } from "components/ProfileBasicInfo";
import dayjs from "dayjs";
import _ from "lodash";
import { Suspense, useState } from "react";
import {
  atom,
  RecoilState,
  RecoilValueReadOnly,
  useRecoilState,
  useRecoilValue,
  useSetRecoilState,
} from "recoil";
import { setRecoil } from "recoil-nexus";
import { syncEffect } from "recoil-sync";
import { reviewPhotos, reviewReportedPhotos, reviewReportedProfiles, reviewStories } from "services";
import {
  ReportReason,
  reviewReportedStories,
} from "services/apis/reported-story";

import {
  errorState,
  rejectedPhotosIdsState,
  rejectedStoriesIdsState,
  reportedPhotosCountState,
  reportedPhotosPageState,
  reportedPhotosRequestId,
  reportedPhotosState,
  reportedProfilesCountQuery,
  reportedProfilesPageState,
  reportedProfilesQuery,
  reportedProfilesRequestIdState,
  reportedStoriesCountState,
  reportedStoriesPageState,
  reportedStoriesRequestId,
  reportedStoriesState,
} from "state";
import { ReviewBody } from "types";
import { ROUTE_URI_PROFILES, getErrorMessage } from "utils";

export const reportsTabState = atom<number>({
  key: "reports_tab",
  default: 0,
  effects: [syncEffect({ refine: number() })],
});

export function Reports() {
  // 定义 reportTabIndex 的可用值
  type ReportTabIndex = 0 | 1 | 2; // 如果有更多的索引，可以继续添加
  const [reportTabIndex, setReportTabIndex] = useRecoilState(reportsTabState);
  const stateMap: Record<ReportTabIndex, {
    state: RecoilState<number>;
    totalState: RecoilValueReadOnly<number>;
    component: JSX.Element; // 添加组件字段
  }> = {
    0: {
      state: reportedProfilesPageState,
      totalState: reportedProfilesCountQuery,
      component: <OpenAllReportedProfiles />,
    },
    1: {
      state: reportedStoriesPageState,
      totalState: reportedStoriesCountState,
      component: <ReviewReportedStories />,
    },
    2: {
      state: reportedPhotosPageState,
      totalState: reportedPhotosCountState,
      component: <ReviewReportedPhotos />,
    },
  };
  // 使用类型断言确保 reportTabIndex 作为索引值
  const { state, totalState, component } = stateMap[reportTabIndex as ReportTabIndex] || { state: null, totalState: null, component: null };
  return (
    <Stack w="100%">
      <MainHeader
        totalState={totalState}
      />
      <Tabs
        isLazy
        defaultIndex={reportTabIndex}
        onChange={(index) => setReportTabIndex(index)}>
        <TabList>
          <Tab>Profiles</Tab>
          <Tab>Stories</Tab>
          <Tab>Photos</Tab>
        </TabList>
        <TabPanels>
          <TabPanel>
            <Suspense fallback={<Spinner />}>
              <ReportedProfilesTable />
            </Suspense>
          </TabPanel>
          <TabPanel>
            <Suspense fallback={<Spinner />}>
              <ReportedStoriesTable />
            </Suspense>
          </TabPanel>
          <TabPanel>
            <Suspense fallback={<Spinner />}>
              <ReportedProfilePhotos />
            </Suspense>
          </TabPanel>
        </TabPanels>
      </Tabs>

      <Spacer />
        <MainFooter>
        <Pagination
          state={
            state
          }
          totalState={
            totalState
          }
        />
        <Spacer />
        {component}
      </MainFooter>
    </Stack>
  );
}

function ReportedProfilesTable() {
  const reportedProfiles = useRecoilValue(reportedProfilesQuery);
  return (
    <TableContainer>
      <Table variant="simple">
        <Thead>
          <Tr>
            <Th>User</Th>
            <Th>Reported By</Th>
          </Tr>
        </Thead>
        <Tbody>
          {reportedProfiles.map((reportedProfile) => (
            <Tr key={reportedProfile.user.id}>
              <Td>
                <ProfileBasicInfo profile={reportedProfile.user} />
              </Td>
              <Td>
                <AvatarGroup
                  size="md"
                  max={2}>
                  {reportedProfile.reportUsers.map((profile) => (
                    <Avatar
                      key={profile.id}
                      name={profile.firstName}
                      src={profile.avatar.url}
                      size="md"
                    />
                  ))}
                </AvatarGroup>
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </TableContainer>
  );
}

function ReportedStoriesTable() {
  const reportedStories = useRecoilValue(reportedStoriesState);
  return (
    <Wrap>
      {reportedStories.map((reportedStory) => (
        <WrapItem key={reportedStory.story.id}>
          <Stack>
            <StoryView
              w="188px"
              story={reportedStory.story}
              rejectedStoriesIdsState={rejectedStoriesIdsState}
            />
            <TagCloud reasons={reportedStory.reasons} />
          </Stack>
        </WrapItem>
      ))}
    </Wrap>
  );
}
function ReportedProfilePhotos() {
  const reportPhotos = useRecoilValue(reportedPhotosState)
  return (
    <Wrap>
      {reportPhotos.map((reportPhoto,index) => (
        <WrapItem key={reportPhoto.photo.id || index}>
          <Stack>
            <PhotoView
              w="188px"
              photo={reportPhoto.photo}
              dataType='photos'
              rejectedPhotoIdsState={rejectedPhotosIdsState}
            />
            <TagCloud reasons={reportPhoto.reasons} />
          </Stack>
        </WrapItem>
      ))}
    </Wrap>
  )
}
export function TagCloud({ reasons }: { reasons: ReportReason[] }) {
  return (
    <Flex
      w="188px"
      flexWrap="wrap"
      justifyContent="flex-start">
      {reasons.map((reason) => (
        <Tag
          key={reason.id}
          m={1}>
          {`${_.capitalize(reason.content)} (${reason.count})`}
        </Tag>
      ))}
    </Flex>
  );
}

function OpenAllReportedProfilesContent() {
  const reports = useRecoilValue(reportedProfilesQuery);

  const resetRequestId = useSetRecoilState(reportedProfilesRequestIdState);

  const openAllInNewTab = () => {
    if (!reports) {
      return;
    }

    reports.forEach((report) => {
      window.open(ROUTE_URI_PROFILES + "/" + report.user.id, "_blank");
    });

    resetRequestId(dayjs().valueOf());
    reviewReportedProfiles({ uids: reports.map((r) => r.user.id) });
  };

  return (
    <Button
      onClick={openAllInNewTab}
      colorScheme="purple">
      Open All In New Tab
    </Button>
  );
}

export function OpenAllReportedProfiles() {
  return (
    <Suspense fallback={<Button disabled>Open All</Button>}>
      <OpenAllReportedProfilesContent />
    </Suspense>
  );
}

export function ReviewReportedStories() {
  return (
    <Suspense
      fallback={
        <Button
          colorScheme="purple"
          disabled>
          Submit
        </Button>
      }>
      <ReviewReportedStoriesContent />
    </Suspense>
  );
}

export function ReviewReportedStoriesContent() {
  const stories = useRecoilValue(reportedStoriesState);
  const rejectedIds = useRecoilValue(rejectedStoriesIdsState);
  const updateRequestId = useSetRecoilState(reportedStoriesRequestId);

  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmit = async () => {
    setIsSubmitting(true);

    const reviewBody: ReviewBody = {
      adoptIds: [],
      refuseIds: [],
    };

    stories.forEach((s) => {
      if (rejectedIds.includes(s.story.id)) {
        reviewBody.refuseIds.push(s.story.id);
      }
    });

    try {
      // review report
      await reviewStories(reviewBody);
      await reviewReportedStories({ storyIds: stories.map((s) => s.story.id) });
      updateRequestId(dayjs().valueOf());
    } catch (error) {
      setRecoil(errorState, getErrorMessage(error));
    }

    setIsSubmitting(false);
  };

  return (
    <Button
      colorScheme="purple"
      onClick={handleSubmit}
      isLoading={isSubmitting}>
      Submit
    </Button>
  );
}

export function ReviewReportedPhotos() {
  return (
    <HStack>
      <Suspense
        fallback={
          <Button colorScheme="purple" disabled>
            Submit
          </Button>
        }>
        <ReviewReportedPhotosContent />
      </Suspense>
    </HStack>
  );
}
export function ReviewReportedPhotosContent() {
  const photos = useRecoilValue(reportedPhotosState);
  const rejectedIds = useRecoilValue(rejectedPhotosIdsState);
  const updateRequestId = useSetRecoilState(reportedPhotosRequestId);

  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmit = async () => {
    setIsSubmitting(true);

    const reviewBody: ReviewBody = {
      adoptIds: [],
      refuseIds: [],
    };

    photos.forEach((s) => {
      if (rejectedIds.includes(s.photo.id)) {
        reviewBody.refuseIds.push(s.photo.id);
      }
    });

    try {
      // review report
      await reviewPhotos(reviewBody);
      await reviewReportedPhotos({ photoIds: photos.map((s) => s.photo.id) });
      updateRequestId(dayjs().valueOf());
    } catch (error) {
      setRecoil(errorState, getErrorMessage(error));
    }
    setIsSubmitting(false);
  };
  return (
    <Button
      colorScheme="purple"
      onClick={handleSubmit}
      isLoading={isSubmitting}>
      Submit
    </Button>)
}
