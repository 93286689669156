import { atom, RecoilValueReadOnly, selector, selectorFamily } from "recoil";
import { pageSizeState } from "./pagination";
import dayjs from "dayjs";
import { fetchViolations } from "services";
import { setRecoil } from "recoil-nexus";
import { errorState } from "./error";
import { getErrorMessage } from "utils";
import { violationsOptionState, violationsRoleState } from "components";
import { MediaStatus, ReviewBody, Violations } from "types";



export const violationsPageState = atom({
  key: "violations/page",
  default: 1,
});

export const violationsRequestIdState = atom({
  key: "violations/requestId",
  default: dayjs().valueOf(),
})

export const rejectedViolationsIdsState = atom<number[]>({
  key: "violations/false",
  default: [],
});

const violationsRespQuery = selector({
  key: "violations/resp",
  get: async ({ get }) => {
    get(violationsRequestIdState)
    const violationStatusValue = get(violationsOptionState);
    const roleStateValue = get(violationsRoleState)
    const params: {
      page: number;
      pageSize: number;
      status: Array<Number>;
      role?: number; // 动态属性，只有在需要时才会存在
    } = {
      page: get(violationsPageState),
      pageSize: get(pageSizeState),
      status: [violationStatusValue],
    };

    if (roleStateValue === 1) {
      params.role = 0; // role 为 number 类型
    } else if (roleStateValue === 2) {
      params.role = 3; // role 为 number 类型
    }
    try {
      return await fetchViolations(params);
    } catch (error) {
      setRecoil(errorState, getErrorMessage(error));
    }
  },
});

export const violationsCountQuery = selector({
  key: "violations/count",
  get: ({ get }) => {
    return get(violationsRespQuery)?.data.page?.total ?? 0;
  },
});

export const violationsQuery: RecoilValueReadOnly<Violations[]> = selector({
  key: "violations/state",
  get: ({ get }) => {
    return get(violationsRespQuery)?.data.data ?? [];
  }
});



export const isFalseViolationsIdsState = selectorFamily({
  key: "isFalseViolationsIdsState",
  get:
    (id: number) =>
      ({ get }) => {
        return get(rejectedViolationsIdsState).includes(id);
      },
});

export const violationsToSubmitQuery = selector({
  key: "violation/submit",
  get: ({ get }) => {
    const violations = get(violationsQuery);
    const rejectedIds = get(rejectedViolationsIdsState);

    const reviewBody: ReviewBody = {
      adoptIds: [],
      refuseIds: [],
    };

    violations.forEach((violation) => {
      if (rejectedIds.includes(violation.id)) {
        reviewBody.refuseIds.push(violation.id);
      } else if (violation.status === MediaStatus.Pending) {
        reviewBody.adoptIds.push(violation.id);
      }
    });

    return reviewBody;
  },
});